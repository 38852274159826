<template>
  <v-container>
    <v-stepper
      v-model="currentStep"
      vertical
    >
      <template v-for="(step) in steps">
        <v-stepper-step
          :key="`${step.number}-step`"
          :complete="currentStep > step.number"
          :step="step.number"
        >
          {{ step.title }}
          <label
            v-if="step.key === 'add' && currentStep === step.number"
            class="body-2"
          >The email addresses should be separated by comma
          </label>
          <label
            v-if="step.key === 'loginMode' && currentStep === step.number"
            class="body-2"
          >Can be changed after the creation under Users -> Manage -> Edit
          </label>
        </v-stepper-step>
        <v-stepper-content
          :key="`${step.number}-content`"
          :step="step.number"
          class="pt-0"
        >
          <v-container>
            <v-row>
              <v-col
                v-if="step.key === 'add'"
                cols="10"
              >
                <v-textarea
                  v-model.trim="emailsInput"
                  no-resize
                  clearable
                  class="ml-2"
                  label="Email addresses"
                  required
                  rows="5"
                  @keydown="verifiedEmails = false"
                />
              </v-col>
              <v-col
                v-if="emails && step.key === 'verify'"
                cols="10"
                class="pa-0 pb-1"
              >
                <label>Total: {{ totalEmails }}</label>
                <emails-container
                  label="Valid emails"
                  :emails="emails.valid"
                />
                <emails-container
                  label="Invalid emails"
                  :emails="emails.invalid"
                />
                <p
                  v-if="emails.invalid.length"
                  class="error--text ml-2 my-2"
                >
                  Remove the invalid emails to continue
                </p>
              </v-col>
              <v-col
                v-if="step.key === 'loginMode'"
                cols="12"
                class="pt-0"
              >
                <login-mode
                  v-model="loginMode"
                />
              </v-col>
              <v-col
                v-if="step.key === 'create' && emails"
                cols="12"
                class="py-0"
              >
                <div class="mb-4">
                  <label class="d-block">Accounts to be created: {{ emails.valid.length }}</label>
                  <label v-if="isSsoCompany">Login mode: {{ loginMode }}</label>
                </div>
              </v-col>
              <v-col
                v-if="step.key === 'summary' && users"
                cols="12"
              >
                <emails-container
                  label="Created accounts"
                  :emails="users.createdUsers.map(u => u.get('username'))"
                />
                <emails-container
                  label="Existing users"
                  :emails="users.existingUsers"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col v-if="showSecondaryButton(step.key)">
                <app-button
                  cancel
                  secondary
                  @click="currentStep = step.number - 1"
                >
                  {{ secondaryButtonLabel(step.key) }}
                </app-button>
              </v-col>
              <v-col>
                <app-button
                  :disabled="primaryButtonDisabled(step.key)"
                  @click="primaryButtonAction(step.key, step.number)"
                >
                  {{ primaryButtonLabel(step.key) }}
                </app-button>
              </v-col>
            </v-row>
          </v-container>
        </v-stepper-content>
      </template>
    </v-stepper>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import { ValidateMixin } from '@kickbox/common-admin';
import FEATURE_NAMES from '@kickbox/common-util/constants/feature-names';
import userService from '@/services/userService';
import EmailsContainer from './EmailsContainer';
import LoginMode from './LoginMode';

export default {
  name: 'CreateUsers',
  components: {
    EmailsContainer,
    LoginMode
  },
  mixins: [ValidateMixin],
  data() {
    return {
      loadingButton: false,
      emailsInput: '',
      emails: null,
      totalEmails: 0,
      users: null,
      currentStep: 1,
      loginMode: null,
      isSsoCompany: false
    };
  },
  computed: {
    ...mapGetters([
      'company'
    ]),
    steps() {
      const customSteps = [
        { number: 1, key: 'add', title: 'Add emails addresses' },
        { number: 2, key: 'verify', title: 'Email addresses verification' },
        { number: !this.isSsoCompany ? 3 : 4, key: 'create', title: ' Create the accounts' },
        { number: !this.isSsoCompany ? 4 : 5, key: 'summary', title: 'Summary' },
      ];
      if (this.isSsoCompany) {
        customSteps.splice(2, 0, {
          number: 3, key: 'loginMode', title: 'Select login mode'
        });
      }
      return customSteps;
    },
  },
  created() {
    this.isSsoCompany = this.company.features[FEATURE_NAMES.SSO];
  },
  methods: {
    getEmails() {
      const emailsArray = this.emailsInput.split(',').map((e) => e.trim()).filter((e) => e);
      this.totalEmails = emailsArray.length;
      return emailsArray.reduce((acc, current) => {
        if (this.checkEmail(current)) {
          acc.valid.push(current);
        } else {
          acc.invalid.push(current);
        }
        return acc;
      }, {
        valid: [],
        invalid: []
      });
    },
    async createUsers() {
      this.loadingButton = true;
      try {
        this.users = await userService.createViewerUsers(this.emails.valid);
        if (this.users.createdUsers.length) {
          this.users.createdUsers.forEach((u) => userService.updateUser({
            id: u.id,
            userProperties: {
              loginMode: this.loginMode
            }
          }));
        }
      } catch (e) {
        this.$store.dispatch('showSnackBar', { text: e.message });
      }
      this.emailsInput = '';
      this.loadingButton = false;
    },
    primaryButtonLabel(key) {
      return key === 'summary' ? 'finish' : 'continue';
    },
    primaryButtonDisabled(key) {
      return (key === 'verify' && this.emails && !!this.emails.invalid.length)
          || (key === 'add' && !this.emailsInput);
    },
    secondaryButtonLabel(key) {
      return key === 'create' ? 'cancel' : 'previous';
    },
    showSecondaryButton(key) {
      return key !== 'add' && key !== 'summary';
    },
    async primaryButtonAction(key, n) {
      switch (key) {
        case 'add':
          this.emails = this.getEmails();
          this.currentStep = n + 1;
          break;
        case 'create':
          await this.createUsers();
          this.currentStep = n + 1;
          break;
        case 'summary':
          this.currentStep = 1;
          break;
        default:
          this.currentStep = n + 1;
          break;
      }
    }
  }
};
</script>

<style scoped>
  label {
    color: rgba(0, 0, 0, .54);
    font-size: 14px;
  }
  .col {
    flex-grow: 0;
  }
</style>

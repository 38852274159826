<template>
  <v-row
    dense
    class="pt-0"
  >
    <v-col
      cols="12"
    >
      <label>{{ label }}: {{ emails.length }}</label>
      <label
        v-if="emails.length"
        class=" ml-4 show-button"
        @click="showEmails = !showEmails"
      >show</label>
    </v-col>
    <v-col
      v-if="showEmails"
      class="my-1 ml-4"
    >
      <label>{{ emails.join(', ') }}</label>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: ['emails', 'label'],
  data() {
    return {
      showEmails: false,
    };
  }
};
</script>

<style scoped>
  .show-button {
    color: #1976d2;
    cursor: pointer;
  }
</style>
